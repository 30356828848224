import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler, getSettingsValue } from '@wix/tpa-settings';
import {
  ButtonState,
  CategoriesTabsState,
  ChallengesCustomEvents,
  ISettingsEvents,
} from './Widget/settingsEvents';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import programListSettings from './settingsParams';
import { renderSeoMetatagsList } from '../../services/SeoMetatagsList';
import { Challenges } from '../../editor/types/Experiments';
import { categoriesDataProviderPropsMap } from '../../contexts/CategoriesDataProvider';
import { challengesListDataProviderPropsMap } from '../../contexts/ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { paidPlansListPropsMap } from '../../contexts/PaidPlans/paidPlansListPropsMap';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};

  // settings panel pub/sub https://github.com/wix-private/tpa-settings#events-between-settings-panel-and-applications
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);
  // subscribe to event. Supports Typescript and events keys maybe only the keys of IEvents interface.
  settingsEventsHandler.on(ChallengesCustomEvents.buttonState, (value) => {
    setProps({
      buttonState: value,
    });
  });

  settingsEventsHandler.on(
    ChallengesCustomEvents.categoriesTabState,
    (categoriesTabsState) => {
      setProps({
        categoriesTabsState,
      });
    },
  );

  // subscribe on reset. When setting panel is closed
  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
      categoriesTabsState: CategoriesTabsState.Regular,
    });
  });

  return {
    async pageReady() {
      const initialProps = await providersPropsMap({
        biSettings: {
          screenName: ScreenNames.ChallengeListWidget,
          preventAutomaticallySendChallengeId: true,
        },
        flowAPI,
        providers: [
          categoriesDataProviderPropsMap,
          challengesListDataProviderPropsMap,
          paidPlansListPropsMap,
        ],
      });

      flowAPI.bi.updateDefaults({
        origin: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
        _uuid: flowAPI.controllerConfig.platformAPIs.bi.ownerId,
        screenName: ScreenNames.ChallengeListWidget,
      });

      const chVisible = getSettingsValue(
        flowAPI.controllerConfig.config.publicData.COMPONENT || {},
        programListSettings.challengesVisible,
      );

      if (
        flowAPI.experiments.enabled(Challenges.enabledRenderSeoMetatagsList)
      ) {
        renderSeoMetatagsList(
          flowAPI,
          initialProps.challengesListData.memberChallenges,
          chVisible,
        );
      }

      try {
        flowAPI.controllerConfig.setProps({
          ...initialProps,
        });
      } catch (e) {
        console.error('[challenge list]: couldnt set initial props', e);
      }

      flowAPI.controllerConfig.setProps({
        editorLoaded: true,
      });
    },
    updateConfig($w, config) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
